import React from "react"

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdown: 0
        };
        this.openDropdown = this.openDropdown.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
      }
          
    openDropdown(a) {
        this.setState({ dropdown: a })
    }
    closeDropdown() {
        this.setState({ dropdown: 0 })
    }
  render() {
      return(
<section className="site__header">
  <header id="navigation" className="navbar-inverse navbar-fixed-top animated-header">
    <div className="top-bar container-fluid">
    </div>
    <div className="container-fluid container-fluid-top">
      <div className="container head-container">
        <div className="row">
          <div className="col-sm-6">
            <p className="navbar-brand">
              <a href="/"><img src={ require('../images/desktop/logo.png') } className="img-responsive" /></a>
              <a className="navbar-brand__note" href="/selecting-cosmetic-dentists">What you need to know</a>
            </p>
          </div>
          <div className="col-md-6 hidden-xs">
            <nav className="collapse navbar-collapse navbar-sec" role="navigation">
              <ul id="nav" className="nav navbar-nav">
                <li onMouseLeave={this.closeDropdown} onMouseEnter={() => this.openDropdown('about')} className="dropdown">
                  <a href="#" className="dropdown-toggle">About Us <i className="fa fa-angle-down"></i></a>
                  <ul className={`dropdown-menu ${this.state.dropdown == 'about' && 'd-block'}`}>
                    <li><a href="/about-dr-kaloti">About Dr Kaloti</a></li>
                    <li><a href="/advanced-technology">Advanced Technology</a></li>
                    <li><a href="/spa-dentistry">Spa Dentistry</a></li>
                    <li><a href="/partner-with-us">Partner With Us</a></li>
                    <li><a href="/ambassador-program">Ambassador Program</a></li>
                  </ul>
                </li>
                <li><a href="/financing">Financing</a></li>
                <li><a href="/contact">Contact Us</a></li>
                <li><a className="btn btn-top" href="/contact"><span>Free Consult</span></a></li>
                <li><a className="nav-btn__tel" href="tel:9058490558">(905) 849-0558</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="navbar-header">
        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="navbar-collapse">
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>
      <nav className="collapse navbar-collapse" role="navigation">
        <ul id="nav" className="nav navbar-nav">
          <li className="active"><a className="nav-btn" href="/">Home</a></li>
          <li className="dropdown hidden-sm hidden-md hidden-lg">
            <a href="#" className="nav-btn dropdown-toggle" data-toggle="dropdown" data-hover="dropdown">About Us <i className="fa fa-angle-down"></i></a>
            <ul className="dropdown-menu">
              <li><a href="/about-dr-kaloti">About Dr Kaloti</a></li>
              <li><a href="advanced-technology">Advanced Technology</a></li>
              <li><a href="/spa-dentistry">Spa Dentistry</a></li>
              <li><a href="/partner-with-us">Partner With Us</a></li>
              <li><a href="/ambassador-program">Ambassador Program</a></li>
            </ul>
          </li>
          <li className="hidden-sm hidden-md hidden-lg"><a href="/financing">Financing</a></li>
          <li className="hidden-sm hidden-md hidden-lg"><a href="/contact">Contact Us</a></li>
          <li onMouseLeave={this.closeDropdown}  onMouseEnter={() => this.openDropdown('services')} className="dropdown">
            <a href="/services" className="dropdown-toggle nav-btn">Services <i className="fa fa-angle-down"></i></a>
            <ul className={`dropdown-menu ${this.state.dropdown == 'services' && 'd-block'}`}>
              <li>
                <a title="Porcelain Veneers" href="/porcelain-veneers">Porcelain Veneers</a>
              </li>
              <li>
                <a title="Full Mouth Reconstruction" href="/full-mouth-reconstruction">Full Mouth Reconstruction</a>
              </li>
              <li>
                <a title="In-Office Bonding" href="/in-office-bonding">In-Office Bonding</a>
              </li>
              <li>
                <a title="Teeth Whitening" href="/teeth-whitening">Teeth Whitening</a>
              </li>
              <li>
                <a title="Clear Correct Invisible Braces" href="/invisible-braces">Clear Correct Invisible Braces</a>
              </li>
              <li>
                <a title="Gum Lift Procedures" href="/gum-lift-procedures">Gum Lift Procedures</a>
              </li>
              <li>
                <a title="All Ceramic Crowns" href="/ceramic-crowns">All Ceramic Crowns</a>
              </li>
              <li>
                <a title="White Fillings" href="/white-fillings">White Fillings</a>
              </li>
              <li>
                <a title="Gaps Between the Teeth" href="/gaps-between-teeth">Gaps Between the Teeth</a>
              </li>
              <li><a href="/dental-implants">Dental Implants</a></li>
            </ul>
          </li>
          <li><a href="/virtual-smile-makeover" className="nav-btn">Virtual Smile Makeover</a></li>
          <li><a className="nav-btn" href="/before-after">Before/After</a></li>
          <li><a className="nav-btn" href="/testimonials">Testimonials</a></li>
          <li><a className="nav-btn" href="/blog/">Blog</a></li>
          <li className="hidden-lg hidden-md hidden-sm hidden-xs">
            <a href="/winawedding/" className="win"><img src="/inc/images/contest/win-a-wedding.png" className="img-responsive" /></a>
          </li>
          <li><a className="nav-btn" href="/press-release/">News/Press</a></li>
          <li className="hidden-xs">
            <ul className="social-button">
              <li className="wow animated zoomIn">
                <a href="https://www.facebook.com/SmileStylistTO" target="_blank"><i className="fa fa-facebook fa-lg"></i></a>
              </li>
              <li className="wow animated zoomIn" data-wow-delay="0.3s">
                <a href="https://twitter.com/smilestylistTO" target="_blank"><i className="fa fa-twitter fa-lg"></i></a>
              </li>
              <li className="wow animated zoomIn" data-wow-delay="0.6s">
                <a href="https://www.instagram.com/smilestylistTO/" target="_blank"><i className="fa fa-instagram fa-lg"></i></a>
              </li>
              <li className="wow animated zoomIn" data-wow-delay="0.6s">
                <a href="https://www.youtube.com/channel/UCJrKCLYvnPzGzMMbOaLFZJg" target="_blank"><i className="fa fa-youtube-play fa-lg"></i></a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</section>

)

      }
    }
export default Header
