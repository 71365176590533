import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
  <section className="site__footer">
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-sm-12">
            <h3>
              <img
                src={require("../images/desktop/logo-footer.png")}
                className="footer-logo"
              />
            </h3>
            <p className="footer-left">
              Dr. Hisham Kaloti is a dental artist who relies on conservative
              procedures to enhance the Health, Function, Beauty and
              Rejuvenation of smiles. His expertise in blending the science of
              dentistry with his artistic ability allows him to create natural
              appearing smiles on hundreds of people from all over the world so
              they can always look and feel their best.
            </p>
            <p className="footer-left-2">
              It is important to note that no dentist can claim that they
              specialize in cosmetic dentistry here Ontario. There is no
              board-certified specialty in cosmetic dentistry. In fact, it is a
              violation of our dental ethics to claim speciality in cosmetic
              dentistry and is punishable by a fine and disciplinary action
              against a dentist's license.
            </p>
            <p className="footer-left-2">
              All product and company names are trademarks™ or registered®
              trademarks of their respective holders. Use of them does not imply
              any affiliation with or endorsement by them.
            </p>
          </div>
          <div className="col-md-2 col-sm-4">
            <h3>Practice Info</h3>
            <ul className="list-unstyled">
              <li>
                <a href="/about-dr-kaloti">About Dr Kaloti</a>
              </li>
              <li>
                <a href="/before-after">Smile Gallery</a>
              </li>
              <li>
                <a href="advanced-technology">Advanced Technology</a>
              </li>
              <li>
                <a href="/blog/">Blog</a>
              </li>
              <li>
                <a href="/giving-back">Giving Back</a>
              </li>
              <li>
                <a href="/financing">Financing</a>
              </li>
              <li>
                <a href="/spa-dentistry">Spa Dentistry</a>
              </li>
              <li>
                <a href="/press-release/">News/Press</a>
              </li>
              <li>
                <a href="/policy">Privacy Policy</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-4">
            <h3>
              <a title="Services" href="/services">
                Smile Stylist Services
              </a>
            </h3>
            <ul className="list-unstyled">
              <li>
                <a title="Porcelain Veneers" href="/porcelain-veneers">
                  Porcelain Veneers
                </a>
              </li>
              <li>
                <a
                  title="Full Mouth Reconstruction"
                  href="/full-mouth-reconstruction"
                >
                  Full Mouth Reconstruction
                </a>
              </li>
              <li>
                <a href="/dental-implants">Dental Implants</a>
              </li>
              <li>
                <a title="In-Office Bonding" href="/in-office-bonding">
                  Composite Bonding
                </a>
              </li>
              <li>
                <a
                  title="Clear Correct Invisible Braces"
                  href="/invisible-braces"
                >
                  Clear Correct Invisible Braces
                </a>
              </li>
              <li>
                <a title="Gum Lift Procedures" href="/gum-lift-procedures">
                  Gum Lift Procedures
                </a>
              </li>
              <li>
                <a title="Gaps Between the Teeth" href="/gaps-between-teeth">
                  Gaps Between the Teeth
                </a>
              </li>
              <li>
                <a
                  title="Virtual Smile Makeover"
                  href="/virtual-smile-makeover"
                >
                  Virtual Smile Makeover
                </a>
              </li>
              <li>
                <a title="Teeth Whitening" href="/teeth-whitening">
                  Teeth Whitening
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-sm-4">
            <h3>Office Hours</h3>
            <p>Mon to Fri: 8 am - 5 pm</p>
            <p>Sat: by appointment</p>
            <p>Sun: closed</p>
            <p>
              <a className="btn btn-footer" href="/contact">
                Book Consultation
              </a>
            </p>
            <h3>Contact Info</h3>
            <p>(905) 849-0558</p>
            <p>info@thesmilestylist.ca</p>
            <p>Address: 123 Trafalgar Rd. Oakville, ON,</p>
            <p>L6J 3G4</p>
          </div>
          <div className="col-sm-12 text-center">
            <p className="legal">
              <a href="https://rainbowtree.co/">
                <p class="legal">
                  Copyright &copy; 2019 Smile Stylist – Site by{" "}
                  <b>Rainbow Tree</b>
                </p>
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  </section>
)

export default Footer
