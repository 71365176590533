import React from "react"

import Header from "./header"
import Footer from "./footer"

import ReactPixel from 'react-facebook-pixel';

class Layout extends React.Component {
  componentDidMount() {
        ReactPixel.init('2454978054724472');
        ReactPixel.pageView(); 	
        if (
         window.location.href.indexOf('mobile') === -1
         && window.matchMedia('(max-width: 768px)').matches
        ) { window.location.pathname = '/mobile' + window.location.pathname }

  }
  render() {
    return (
        <>
        <Header/>
            <main className="site__site">{this.props.children}</main>
        <Footer />
        </>
    )
  }
}

export default Layout
