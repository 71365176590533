import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, keywords }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        ["We’re Excited to be a part of Z103.5’s Dream Wedding Contest!", 
        "Botox - The Cosmetic Treatment | The Smile Stylist",
        "Enter To Win | Smile Makeover | The Smile Stylist",
        "Oakville Cosmetic Dentistry - Thank You"].includes(title) && {
            name: `robots`,
            content: `noindex, nofollow`
        }
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: `The Smile Stylist, the top-rated Cosmetic Dentist in the Greater Toronto Area specializes in porcelain veneers, smile makeovers and crafting unforgettable smiles.`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SEO
